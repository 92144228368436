/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        //add commas to large numbers
        function addCommas(yourNumber) {
          //Seperates the components of the number
          var n= yourNumber.toString().split(".");
          //Comma-fies the first part
          n[0] = n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          //Combines the two sections
          return n.join(".");
        }

        var rangeSlider = $('#pricerange');
        var fpmin = 0;
        var fpmax = 10000;
        function priceRange() {
          var frange = rangeSlider.val();
          var franges = frange.split(',');
          fpmin = franges[0];
          fpmax = franges[1];
          rangeSlider.parent('.app-slider').children('.min-val').children('span').html(addCommas(fpmin));
          rangeSlider.parent('.app-slider').children('.max-val').children('span').html(addCommas(fpmax));
          $('#minprice').val(franges[0]);
          $('#maxprice').val(franges[1]);
        }
        if(document.getElementById('pricerange')) {
          priceRange();
        }
        if(document.getElementById('pricerange')) {
          rangeSlider.slider().on('slideStop', function(){
            var frango = $(this).val();
            var frangos = frango.split(',');
            fpmin = frangos[0];
            fpmax = frangos[1];
            $(this).parent('.app-slider').children('.min-val').children('span').html(addCommas(fpmin));
            $(this).parent('.app-slider').children('.max-val').children('span').html(addCommas(fpmax));
            $('#minprice').val(frangos[0]);
            $('#maxprice').val(frangos[1]);
          });
        }
        
        var sizeSlider = $('#sizerange');
        var ssmin = 0;
        var ssmax = 10000;
        function sizeRange() {
          var srange = sizeSlider.val();
          var sranges = srange.split(',');
          ssmin = sranges[0];
          ssmax = sranges[1];
          sizeSlider.parent('.size-slider').children('.min-val').children('span').html(addCommas(ssmin));
          sizeSlider.parent('.size-slider').children('.max-val').children('span').html(addCommas(ssmax));
          $('#minsize').val(sranges[0]);
          $('#maxsize').val(sranges[1]);
        }
        if(document.getElementById('sizerange')) {
          sizeRange();
        }
        if(document.getElementById('sizerange')) {
          sizeSlider.slider().on('slideStop', function(){
            var srango = $(this).val();
            var srangos = srango.split(',');
            ssmin = srangos[0];
            ssmax = srangos[1];
            $(this).parent('.size-slider').children('.min-val').children('span').html(addCommas(ssmin));
            $(this).parent('.size-slider').children('.max-val').children('span').html(addCommas(ssmax));
            $('#minsize').val(srangos[0]);
            $('#maxsize').val(srangos[1]);
          });
        }
        
        
        
        var heatSlider = $('#heatrange');
        var btmin = 0;
        var btmax = 10000;
        function heatRange() {
          var hrange = heatSlider.val();
          var hranges = hrange.split(',');
          btmin = hranges[0];
          btmax = hranges[1];
          heatSlider.parent('.heat-slider').children('.min-val').children('span').html(addCommas(btmin));
          heatSlider.parent('.heat-slider').children('.max-val').children('span').html(addCommas(btmax));
          $('#minheat').val(hranges[0]);
          $('#maxheat').val(hranges[1]);
        }
        if(document.getElementById('heatrange')) {
          heatRange();
        }
        if(document.getElementById('heatrange')) {
          heatSlider.slider().on('slideStop', function(){
            var hrango = $(this).val();
            var hrangos = hrango.split(',');
            btmin = hrangos[0];
            btmax = hrangos[1];
            $(this).parent('.heat-slider').children('.min-val').children('span').html(addCommas(btmin));
            $(this).parent('.heat-slider').children('.max-val').children('span').html(addCommas(btmax));
            $('#minheat').val(hrangos[0]);
            $('#maxheat').val(hrangos[1]);
          });
        }
        
        
        var capacity_w_Slider = $('#capacityrangew');
        var cpwmin = 0;
        var cpwmax = 10000;
        function capacity_w_Range() {
          var cprange = capacity_w_Slider.val();
          var cpranges = cprange.split(',');
          cpwmin = cpranges[0];
          cpwmax = cpranges[1];
          capacity_w_Slider.parent('.capacity-slider-w').children('.min-val').children('span').html(addCommas(cpwmin));
          capacity_w_Slider.parent('.capacity-slider-w').children('.max-val').children('span').html(addCommas(cpwmax));
          $('#mincapacity_w').val(cpranges[0]);
          $('#maxcapacity_w').val(cpranges[1]);
        }
        if(document.getElementById('capacityrangew')) {
          capacity_w_Range();
        }
        if(document.getElementById('capacityrangew')) {
          capacity_w_Slider.slider().on('slideStop', function(){
            var cprango = $(this).val();
            var cprangos = cprango.split(',');
            cpwmin = cprangos[0];
            cpwmax = cprangos[1];
            $(this).parent('.capacity-slider-w').children('.min-val').children('span').html(addCommas(cpwmin));
            $(this).parent('.capacity-slider-w').children('.max-val').children('span').html(addCommas(cpwmax));
            $('#mincapacity_w').val(cprangos[0]);
            $('#maxcapacity_w').val(cprangos[1]);
          });
        }

        var capacity_d_Slider = $('#capacityranged');
        var cpdmin = 0;
        var cpdmax = 10000;
        function capacity_d_Range() {
          var cprange = capacity_d_Slider.val();
          var cpranges = cprange.split(',');
          cpdmin = cpranges[0];
          cpdmax = cpranges[1];
          capacity_d_Slider.parent('.capacity-slider-d').children('.min-val').children('span').html(addCommas(cpdmin));
          capacity_d_Slider.parent('.capacity-slider-d').children('.max-val').children('span').html(addCommas(cpdmax));
          $('#mincapacity_d').val(cpranges[0]);
          $('#maxcapacity_d').val(cpranges[1]);
        }
        if(document.getElementById('capacityranged')) {
          capacity_d_Range();
        }
        if(document.getElementById('capacityranged')) {
          capacity_d_Slider.slider().on('slideStop', function(){
            var cprango = $(this).val();
            var cprangos = cprango.split(',');
            cpdmin = cprangos[0];
            cpdmax = cprangos[1];
            $(this).parent('.capacity-slider-d').children('.min-val').children('span').html(addCommas(cpdmin));
            $(this).parent('.capacity-slider-d').children('.max-val').children('span').html(addCommas(cpdmax));
            $('#mincapacity_d').val(cprangos[0]);
            $('#maxcapacity_d').val(cprangos[1]);
          });
        }
        
        
        var capacitySlider = $('#capacityrange');
        var cpmin = 0;
        var cpmax = 10000;
        function capacityRange() {
          var cprange = capacitySlider.val();
          var cpranges = cprange.split(',');
          cpmin = cpranges[0];
          cpmax = cpranges[1];
          capacitySlider.parent('.capacity-slider').children('.min-val').children('span').html(cpmin);
          capacitySlider.parent('.capacity-slider').children('.max-val').children('span').html(cpmax);
          $('#mincapacity').val(cpranges[0]);
          $('#maxcapacity').val(cpranges[1]);
          console.log(cpranges[0]);
        }
        if(document.getElementById('capacityrange')) {
          capacityRange();
        }
        if(document.getElementById('capacityrange')) {
          capacitySlider.slider().on('slideStop', function(){
            var cprango = $(this).val();
            var cprangos = cprango.split(',');
            cpmin = cprangos[0];
            cpmax = cprangos[1];
            $(this).parent('.capacity-slider').children('.min-val').children('span').html(addCommas(cpmin));
            $(this).parent('.capacity-slider').children('.max-val').children('span').html(addCommas(cpmax));
            $('#mincapacity').val(cprangos[0]);
            $('#maxcapacity').val(cprangos[1]);
          });
        }

       
        var kiloSlider = $('#kilorange');
        var kwmin = 0;
        var kwmax = 10000;
        function kiloRange() {
          var kwrange = kiloSlider.val();
          var kwranges = kwrange.split(',');
          kwmin = kwranges[0];
          kwmax = kwranges[1];
          kiloSlider.parent('.kilo-slider').children('.min-val').children('span').html(addCommas(kwmin));
          kiloSlider.parent('.kilo-slider').children('.max-val').children('span').html(addCommas(kwmax));
          $('#minkilo').val(kwranges[0]);
          $('#maxkilo').val(kwranges[1]);
        }
        if(document.getElementById('kilorange')) {
          kiloRange();
        }
        if(document.getElementById('kilorange')) {
          kiloSlider.slider().on('slideStop', function(){
            var kwrango = $(this).val();
            var kwrangos = kwrango.split(',');
            kwmin = kwrangos[0];
            kwmax = kwrangos[1];
            $(this).parent('.kilo-slider').children('.min-val').children('span').html(addCommas(kwmin));
            $(this).parent('.kilo-slider').children('.max-val').children('span').html(addCommas(kwmax));
            $('#minkilo').val(kwrangos[0]);
            $('#maxkilo').val(kwrangos[1]);
          });
        }
        
        
        function navMargins() {
          if($('#mobile-toggle').css('display') === 'none') {
            var pnw = $('#menu-primary-navigation').outerWidth();
            var pn = $('#menu-primary-navigation');
            var liw = 0;
            var lic = (pn.children('li').length)-1;
            
            pn.children('li').each(function() {
              liw += $(this).outerWidth();
            });
            var lim = (((pnw-liw)/lic)/2);
            pn.children('li').css({'margin':'0 '+lim+'px'});
          }
        }
        navMargins();
        $(window).resize(function(){
          navMargins();
        });
        
        $('#mobile-toggle').click(function() {
          $('.nav-primary').slideToggle('fast');
        });
        $('li.menu-item-has-children').children('a').click(function(e) {
          if($(window).width() < 768) {
            e.preventDefault();
            $(this).parent('li.menu-item-has-children').children('.sub-menu').slideToggle('fast');
            $(this).parent('li.menu-item-has-children').toggleClass('open');
          }
        });
        
        var defSlider = $('.default-slider-horizontal');
        defSlider.bxSlider({
          auto: true,
          pager: false,
          controls: true,
          adaptiveHeight:true
        });
        $('.bx-controls-direction').children('a').click(function(){
          defSlider.stopAuto();
          defSlider.startAuto();
        });
        
        var mapSlider = $('#map-slider');
        mapSlider.bxSlider({
          auto: true,
          pager: false,
          controls: true,
          autoHover: true,
          pause: 7000
        });
        $('.bx-controls-direction').children('a').click(function(){
          mapSlider.stopAuto();
          mapSlider.startAuto();
        });
        
        var defSliderFade = $('.default-slider-fading');
        defSliderFade.bxSlider({
          mode: 'fade',
          auto: true,
          pager: false,
          controls: false
        });
        
        var galSlider = $('.gallery-slider');
        galSlider.bxSlider({
          controls: true,
          adaptiveHeight: true
        });
        
        $('.gallery-link').magnificPopup({
          type: 'image',
          image: {
            titleSrc: function(item) { 
              return item.el.children('.the-img').attr('caption');
            },
            verticalFit: true
          },
          closeOnBgClick: true,
          showCloseBtn: true,
          gallery: {
            enabled:true,
          },
        });
        
        $('.other-file').magnificPopup({
          type: 'image',
          image: {
            titleSrc: function(item) { 
              return item.el.children('.the-img').attr('caption');
            },
            verticalFit: true
          },
          closeOnBgClick: true,
          showCloseBtn: true,
        });
        
        $('.pdf-popup').magnificPopup({
          disableOn: 700,
          type: 'iframe',
          mainClass: 'mfp-fade',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
        
        $('.inline-popup').click(function(e){
          e.preventDefault();
        });
        
        $('.inline-popup').magnificPopup({
          type:'inline',
          midClick: true
        });
        
        $('img[usemap]').rwdImageMaps();
        
        function vtip() {    
          this.xOffset = -10; // x distance from mouse
          this.yOffset = 10; // y distance from mouse       

          $(".vtip").unbind().hover(    
            function(e) {
              this.t = this.title;
              this.title = ''; 
              this.top = (e.pageY + yOffset); this.left = (e.pageX + xOffset);
              $('body').append( '<p id="vtip"><img id="vtipArrow" />' + this.t + '</p>' );
              $('p#vtip').css("top", this.top+"px").css("left", this.left+"px").fadeIn("slow");
            },
            function() {
              this.title = this.t;
              $("p#vtip").fadeOut("slow").remove();
            }
          ).mousemove(
            function(e) {
              this.top = (e.pageY + yOffset);
              this.left = (e.pageX + xOffset);
              $("p#vtip").css("top", this.top+"px").css("left", this.left+"px");
            }
          );            
        }
        vtip();
        
        $('article.appliance').each(function() {
          if($(window).width() > 544) {
            var ah = $(this).height();
            $(this).children('.compare').css({'height':ah+'px'});
          }
        });
        
        $('.video-link').magnificPopup({
           type: 'iframe',
           gallery:{
             enabled:true
           }
         });
        
         $('.video-container').fitVids();
        
         $('h3.question').click(function(){
           $(this).parent('li').toggleClass('open');
           $(this).parent('li').children('.answer').slideToggle('fast');
         });
        
        
        var comparre = 0;
        $('.chk-compare').each(function() {
          if(this.checked) {
            comparre += 1;
            if(comparre >= 2) {
              $('#btn-compare').removeClass('disabled');
            }
          }
        });
        
        $('.chk-compare').change(function() {
          if(this.checked) {
            comparre += 1;
            if(comparre >= 2) {
              $('#btn-compare').removeClass('disabled');
            } else {
              $('#btn-compare').addClass('disabled');
            }
          } else {
            comparre -=1;
            if(comparre >= 2) {
              $('#btn-compare').removeClass('disabled');
            } else {
              $('#btn-compare').addClass('disabled');
            }
          }
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var slider = $('#home-slider');
        slider.bxSlider({
          mode:'fade',
          controls: false,
          auto: true
        });
        $('.bx-pager-link').click(function(){
          slider.stopAuto();
          slider.startAuto();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'tax_appliance_categories': {
      init: function() {
        $('#filter-toggle').click(function() {
          $('form.filter-wrap').slideToggle('fast');
          $(this).children('span').toggleClass('open');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
